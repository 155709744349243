import React from "react";
import "./App.css";
import { Layout } from "./shared/Layout";

// function numToFlower(n) {
//   if (n > 0.7) return "red_fl.png";
//   if (n > 0.4) return "pink_fl.png";

//   // higher chance yellow, because cute.
//   return "yellow_fl.png";
// }

function App() {
  // const [flowers, setFlowers] = useState([]);

  // useInterval(() => {
  //   const addFlower = Math.random() > 0.33;
  //   const color = numToFlower(Math.random());
  //   const id = `${Date.now()}_${color}_fl`;

  //   if (addFlower) {
  //     const coordX = Math.random() * 85;
  //     const coordY = Math.random() * 35;

  //     setFlowers([
  //       ...flowers,
  //       [`${coordX + 5}vw`, `${coordY + 30}vh`, color, id],
  //     ]);
  //   }
  // }, 200);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          paddingTop: "10vh",
        }}
      >
        <img src="M_and_D_001.png" style={{ height: "40vh", margin: "auto" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: "2px",
            maxHeight: "90vh",
          }}
        >
          <img
            src="II_MegandDavid.png"
            alt="meghan marie barker and david siah"
            style={{
              maxHeight: "20vh",
              maxWidth: "370px",
              margin: "auto",
            }}
          />
          <img
            src="IV_invite_you_to_celebrate_their_marriage.png"
            alt="invite you to celebrate their marriage"
            style={{
              maxHeight: "13vh",
              maxWidth: "370px",
              margin: "auto",
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default App;
