import "./App.css";
import { Layout } from "./shared/Layout";
import { useWindowDimensions } from "./useWindowDimensions";

const SPOTS = {
  weddingLocations: [
    {
      name: "Parker's Table",
      link: "https://maps.app.goo.gl/bppdjBtSbDsJ8pqZA",
    },
    {
      name: "The Cheshire Inn",
      link: "https://maps.app.goo.gl/npSkXfswExpHcJgV7",
    },
    {
      name: "St. James the Greater Catholic Church",
      link: "https://maps.app.goo.gl/TmrqdRBDDgjmB2Kt9",
    },
    {
      name: "Le Champ de Fleurs",
      link: "https://maps.app.goo.gl/dbokuKJxKstX3TEP7",
    },
  ],
  postCeremony: [
    {
      name: "Heavy Riff Brewing Company",
      link: "https://maps.app.goo.gl/T758pqkLDgrNXVKa9",
    },
    {
      name: "Felix's Pizza Pub",
      link: "https://maps.app.goo.gl/cETxVChA2DoUBuZL9",
    },
    {
      name: "Sweet EM's Coffee & Ice Cream",
      link: "https://maps.app.goo.gl/WxYUQjE2USDCX9Hx6",
    },
    {
      name: "Tamm Avenue Bar",
      link: "https://maps.app.goo.gl/b1vdhbPFgsuEQ75aA",
    },
    {
      name: "Seamus McDaniel's",
      link: "https://maps.app.goo.gl/5YCpnq5sgQ6mB3DAA",
    },
  ],
  breweries: [
    {
      name: "Perennial Artisan Ales",
      link: "https://maps.app.goo.gl/64CwRmLmWwmKa6nv7",
    },
    {
      name: "4 Hands Brewing Co.",
      link: "https://maps.app.goo.gl/hpRCgADhgmA8Xzgd6",
    },
    {
      name: "Civil Life Brewing Co.",
      link: "https://maps.app.goo.gl/fp3SrMS64qrnaYA98",
    },
    {
      name: "Urban Chestnut Grove Brewery and Bierhall",
      link: "https://maps.app.goo.gl/r7wWKYUWvZ5AxKdM8",
    },
    {
      name: "Schlafley Bottleworks",
      link: "https://maps.app.goo.gl/YEffgpUF9Gwy32Qs7",
    },
    {
      name: "Anheuser-Busch St. Louis Brewery",
      link: "https://maps.app.goo.gl/HvkCA9GEgc2gDzjn9",
    },
  ],
  pois: [
    {
      name: "The Arch",
      link: "https://maps.app.goo.gl/QzQCeNwxrEfbJBZUA",
    },
    {
      name: "Saint Louis Art Museum",
      link: "https://maps.app.goo.gl/kxhF9GQRouT18h7F7",
    },
    {
      name: "Forest Park",
      link: "https://maps.app.goo.gl/WXxdtTYL9uZNCDt29",
    },
    {
      name: "The City Museum",
      link: "https://maps.app.goo.gl/VeHVb21LGoy269BV9",
    },
    {
      name: "Missouri Botanical Garden",
      link: "https://maps.app.goo.gl/61esfZ7abMxb7oSo7",
    },
    {
      name: "Mildred Lane Kemper Art Museum",
      link: "https://maps.app.goo.gl/u2NFsaGvjRo1HJeu8",
    },
  ],
  food: [
    {
      name: "Ted Drewes Frozen Custard",
      link: "https://maps.app.goo.gl/KqxUXyjvxQyAjFuh6",
    },
    {
      name: "Bogart's Smokehouse",
      link: "https://maps.app.goo.gl/CLWzoTom6iSLKVgv8",
    },
    {
      name: "Pappy's Smokehouse",
      link: "https://maps.app.goo.gl/MSVDE5TKwgmkFahZ7",
    },
    {
      name: "Sugarfire Smoke House",
      link: "https://maps.app.goo.gl/tEoh2FU17zocJdSB6",
    },
    { name: "Gioia's Deli", link: "https://maps.app.goo.gl/yr7m16NT6GR9r6Ao6" },
    { name: "Imo's pizza", link: "https://maps.app.goo.gl/oFBhMBR9Aiamvkys9" },
    {
      name: "Bowood Garden",
      link: "https://maps.app.goo.gl/Rx8mQLgLYBRYLGwz9",
    },
    {
      name: "Living Room Coffee & Kitchen",
      link: "https://maps.app.goo.gl/J3MqpAmwFS7BX6tK7",
    },
    {
      name: "Blueprint Coffee",
      link: "https://maps.app.goo.gl/oWUHmbgzWj5na94M9",
    },
    {
      name: "Fiddlehead Fern Cafe",
      link: "https://maps.app.goo.gl/aAL3h1TrutCJJ5AP6",
    },
  ],
};
const divStyle = { width: "80%" };

function Visit() {
  const { width } = useWindowDimensions();
  const belowDesktopWidth = width < 400;
  const flexStyle = belowDesktopWidth
    ? {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }
    : {
        display: "flex",
        justifyContent: "space-around",
        gap: "2em",
        marginLeft: "17%",
        marginRight: "17%",
        flexWrap: "wrap",
      };

  return (
    <Layout>
      <h1 style={{ textAlign: "center" }}>Visiting St. Louis</h1>
      <div style={flexStyle}>
        <div style={divStyle}>
          <h2 style={{ textAlign: "center" }}>Wedding Locations</h2>
          <ul>
            {SPOTS.weddingLocations.map((f) => (
              <LinkedBullet {...f} />
            ))}
          </ul>
        </div>
        <div style={divStyle}>
          <h2 style={{ textAlign: "center" }}>Points of Interest</h2>
          <ul>
            {SPOTS.pois.map((f) => (
              <LinkedBullet {...f} />
            ))}
          </ul>
        </div>
        <div style={divStyle}>
          <h2 style={{ textAlign: "center" }}>Breweries</h2>
          <ul>
            {SPOTS.breweries.map((f) => (
              <LinkedBullet {...f} />
            ))}
          </ul>
        </div>
        <div style={divStyle}>
          <h2 style={{ textAlign: "center" }}>Food</h2>
          <ul>
            {SPOTS.food.map((f) => (
              <LinkedBullet {...f} />
            ))}
          </ul>
        </div>
        <div style={divStyle}>
          <h2 style={{ textAlign: "center" }}>Between Ceremony & Reception</h2>
          <ul>
            {SPOTS.postCeremony.map((f) => (
              <LinkedBullet {...f} />
            ))}
          </ul>
        </div>
        <div style={divStyle}>
          <img
            src="light_snail.png"
            style={{
              width: "200px",
              position: "relative",
            }}
          />
          <div style={{ height: "7vh" }}></div>
        </div>
      </div>
    </Layout>
  );
}

function LinkedBullet(p) {
  return (
    <li>
      <a href={p.link} target="_blank" rel="noreferrer">
        {p.name}
      </a>
    </li>
  );
}

export default Visit;
