import { Footer } from "./Footer";

export function Layout({ children }) {
  return (
    <>
      <div
        id="layoutcontent"
        style={{
          height: "93vh",
          overflow: "scroll",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        <FullWidthGarnish />
        {children}
      </div>
      <Footer />
    </>
  );
}

function FullWidthGarnish() {
  return (
    <>
      <img
        src="camomille__0000_Layer-1.png"
        style={{
          zIndex: -2,
          width: "40vw",
          maxWidth: "500px",
          position: "absolute",
          left: 0,
          bottom: "10vh",
        }}
      />
      <img
        src="camomille__0001_Layer-2.png"
        style={{
          zIndex: -2,
          width: "18vw",
          maxWidth: "200px",
          position: "absolute",
          left: "2vw",
          top: "0vh",
          transform: "scaleY(-1)",
        }}
      />
      <img
        src="camomille__0002_Layer-3.png"
        style={{
          zIndex: -2,
          width: "4vw",
          maxWidth: "400px",
          position: "absolute",
          left: "18vw",
          top: "0vh",
          transform: "scaleY(-1)",
        }}
      />
      <img
        src="camomille__0003_Layer-4.png"
        style={{
          zIndex: -2,
          width: "6vw",
          position: "absolute",
          right: "14vw",
          bottom: "7vh",
        }}
      />
      <img
        src="camomille__0004_Layer-5.png"
        style={{
          zIndex: -2,
          width: "20vw",
          maxWidth: "275px",
          position: "absolute",
          right: "0vw",
          bottom: "0",
          transform: "scaleY(-1)",
        }}
      />
      <img
        src="camomille__0005_Layer-6.png"
        style={{
          zIndex: -2,
          width: "20vw",
          maxWidth: "300px",
          position: "absolute",
          right: "0vw",
          top: "3vh",
        }}
      />
    </>
  );
}

function SkinnyGarnish() {
  return (
    <>
      <img
        src="camomille__0000_Layer-1.png"
        style={{
          zIndex: -2,
          width: "40vw",
          maxWidth: "500px",
          position: "absolute",
          left: 0,
          bottom: "10vh",
        }}
      />
      <img
        src="camomille__0002_Layer-3.png"
        style={{
          zIndex: -2,
          width: "6vw",
          maxWidth: "400px",
          position: "absolute",
          right: "10vw",
          top: "0vh",
          transform: "scaleY(-1)",
        }}
      />
      <img
        src="camomille__0005_Layer-6.png"
        style={{
          zIndex: -2,
          width: "26vw",
          maxWidth: "300px",
          position: "absolute",
          right: "-4vw",
          top: "5vh",
        }}
      />
    </>
  );
}
