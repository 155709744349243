import { Link, NavLink } from "react-router-dom";
import "../App.css";

export function Footer() {
  return (
    <div
      id="navbar"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        height: "5vh",
        backgroundColor: "white",
      }}
    >
      <div className="footer-navbar-container">
        <NavLink to="/agenda">agenda</NavLink>
        <NavLink to="/faq">faq</NavLink>
        <NavLink to="/visit">visit</NavLink>
        <NavLink to="/lodging">lodging</NavLink>
        <Link to="https://www.zola.com/registry/meg_david_2024" target="_blank">
          registry
        </Link>
      </div>
    </div>
  );
}
