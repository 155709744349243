import "./index.css";
import Agenda from "./Agenda";
import App from "./App";
import Faq from "./Faq";
import Lodging from "./Lodging";
import Visit from "./Visit";
import React from "react";
import reportWebVitals from "./reportWebVitals";
import { Analytics } from "@vercel/analytics/react";
import { Footer } from "./shared/Footer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { sendToVercelAnalytics } from "./vitals";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/agenda", element: <Agenda /> },
  { path: "/visit", element: <Visit /> },
  { path: "/lodging", element: <Lodging /> },
  { path: "/faq", element: <Faq /> },
]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <Footer />
    </RouterProvider>

    <Analytics />
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
