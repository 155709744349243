import "./App.css";
import { Layout } from "./shared/Layout";

function Faq() {
  return (
    <Layout>
      <div
        style={{
          margin: "auto",
          width: "66%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>FAQ</h1>
        <div style={{ marginLeft: "2%", padding: "2px" }}>
          <p>
            <em>
              if your question is not addressed here please{" "}
              <a href="mailto:meghandavid@protonmail.com">email us</a>. we will
              continue to update this page as we approach the wedding date
            </em>
          </p>
        </div>
        <QA q="Dress code" a="black tie optional" />
        <QA
          q="Will your wedding be indoors or outdoors"
          a="our ceremony will be held inside St. James the Greater, but our reception is held on a flower farm. expect to spend time outdoors and see the next point for more info"
        />
        <QA
          q="What is the weather like in the area"
          a="summers in st. louis are hot, and while september starts to see a little cooling (think 65 - 85 F), please keep this in mind as parts of our celebration will be outdoors"
        />
        <QA
          q="May I bring a plus one"
          a="while we would love everyone to open the invitation to everyone, our venue is limited in space and that means not every guest will have a plus one. your RSVP card will specify whether you may bring a plus one. if you are not sure, please contact us."
        />
        <QA
          q="What type of food will be served at the wedding"
          a="We are excited to share a special meal with you, a farm-to-table tasting menu."
        />
        <QA
          q="Will there be meal options for guests with dietary restrictions or allergies"
          a="please email us our reception venue will accommodate"
        />
        <div style={{ marginLeft: "2%" }}>
          <h4>Will there be transportation?</h4>
          <p>
            <em>
              yes, here is a breakdown of the transportation for the evening:
            </em>
          </p>
          <div style={{ paddingLeft: "1em", color: "#00236a" }}>
            <p>
              <b>4:15 - 4:30PM</b> shuttle will depart from{" "}
              <b>parking lot at St. James the Greater Church parking lot</b>{" "}
              (1366 Tamm Ave, St. Louis, MO 63139)
            </p>
            <p>
              *** If you choose to leave your car at the parking lot, please
              park in the larger section of the lot located behind the
              chain-linked fence. The gate will not be closed. The parish will
              be using the small parking lot for an evening service.
            </p>
            <p>
              <b>10:30 - 10:45 PM</b> shuttle will depart from reception at
              Stone Soup Cottage.
            </p>
            <p>
              <b>11:00 - 11:15 PM</b> -- guest drop off #1, The Cheshire Hotel
              (6300 Clayton Road St. Louis, MO 63117)
            </p>
            <p>
              <b>11:15 - 11:30 PM</b> -- guest drop off #2, St. James the
              Greater Church parking lot (1336 Tamm Ave, St. Louis, MO 63139)
            </p>
          </div>
        </div>

        <QA
          q="Is there parking near the venues"
          a="yes, please see the agenda page for more information about parking."
        />
        <QA
          q="Do you have a wedding registry"
          a="yes, please see the registry tab"
        />
        <QA
          q="How do I RSVP"
          a="your invitation includes an RSVP postcard. we have already included a postage stamp and return address - just fill out the front and place it in a mail box :) if you are unable to mail it for any reason you may also send us a photo of your rsvp card"
        />
        <img
          src="light_snail.png"
          style={{
            width: "200px",
            position: "relative",
          }}
        />
        <div style={{ height: "7vh" }}></div>
      </div>
    </Layout>
  );
}

function QA({ q, a }) {
  return (
    <div style={{ marginLeft: "2%" }}>
      <h4>{q}?</h4>
      <p>
        <em>{a}</em>
      </p>
    </div>
  );
}

export default Faq;
