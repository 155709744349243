import "./App.css";
import { Layout } from "./shared/Layout";

function Lodging() {
  return (
    <Layout>
      <div
        style={{
          margin: "auto",
          width: "70%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Lodging Options</h1>
        <div
          className="Agenda-item"
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>The Cheshire</h2>
            <h4>
              <a href="https://www.cheshirestl.com/">Hotel Info</a>
            </h4>
            <SlimP>
              This hotel is fairly central to the points of interest & our
              ceremony's church, St. James the Greater.
            </SlimP>
          </div>
          <img
            style={{ width: "20vw", padding: "2vw" }}
            alt="cosmos illustration"
            src="linercosmos_mb.png"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Airbnb & Other alternative lodging</h2>
            <SlimP>
              There are many listings available on AirBNB and VRBO. Look in the
              following St. Louis neighborhoods to be near the Church: Demun,
              High-Point, Richmond Heights, Clayton-Tamm, Franz Park, Brentwood,
              and Frontenac.
            </SlimP>
            <div style={{ height: "7vh" }}></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export function SlimP({ children }) {
  return <p style={{ maxWidth: "70ch" }}>{children}</p>;
}

export default Lodging;
