import "./App.css";
import { Layout } from "./shared/Layout";

function Agenda() {
  return (
    <Layout>
      <div
        style={{
          margin: "auto",
          width: "70%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Agenda</h1>
        <h3 style={{ textAlign: "center" }}>
          <em>September 6, 2024</em>
        </h3>
        <div
          className="Agenda-item"
          style={{
            padding: "1%",
            textAlign: "center",
          }}
        >
          <h2>Welcome Dinner at Parker's Table</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              gap: 2,
            }}
          >
            <p style={{ maxWidth: "70ch", margin: "auto" }}>
              from <b>6:30 - 8:00 pm</b>
            </p>
            <p style={{ maxWidth: "70ch", margin: "auto" }}>
              If you are able to, please join us for drinks and food.
            </p>
            <h4>
              <a href="https://maps.app.goo.gl/fVjjBbVGMvJUHtHz5">
                7118 Oakland Ave, <br />
                Richmond Heights, MO 63117
              </a>
            </h4>
            <p>
              PARKING: street parking available in front of and around the
              restaurant.
            </p>
          </div>
          <img
            style={{ width: "50%", padding: "2vw" }}
            alt="cosmos illustration"
            src="linercosmos_mb.png"
          />
        </div>
        <h3 style={{ textAlign: "center" }}>
          <em>September 7, 2024</em>
        </h3>
        <div
          className="Agenda-item"
          style={{
            padding: "1%",
            textAlign: "center",
          }}
        >
          <h2>Ceremony</h2>
          <img
            src="dogwood_ds.png"
            alt="st james the greater church illustration"
            style={{ width: "50%" }}
          />
          <p>
            Wedding Mass begins at <b>2:00 PM</b>
          </p>
          <h3>St. James the Greater Catholic Church</h3>
          <h4>
            <a href="https://maps.app.goo.gl/kTeKdiudm6Uwuagg8">
              6401 Wade Ave, <br />
              St. Louis, MO 63139
            </a>
          </h4>
          <p>
            PARKING: A parking lot is located across the street from the church.
          </p>
          <p>
            <b>
              The Parking lot address is: 1366 Tamm Ave, St. Louis, MO 63139
            </b>
          </p>
        </div>
        <header
          className="Agenda-item"
          style={{
            padding: "1%",
            textAlign: "center",
          }}
        >
          <h2>Reception</h2>
          <img
            src="stonesoup_ds.png"
            alt="stone soup illustration"
            style={{ width: "50%" }}
          />
          <p>
            <em>
              Doors open at <b>5:00</b> for cocktail hour. Dinner & dancing will
              begin at <b>6:00 PM</b>.
            </em>
          </p>
          <h3>Le Champ De Fleurs by Stone Soup Cottage</h3>
          <h4>
            <a href="https://maps.app.goo.gl/35JzMMWmkEA2SbrF6">
              5809 Hwy N, <br />
              Cottleville, MO 63304
            </a>
          </h4>
          <p>PARKING: A parking lot is available at the restaurant.</p>
        </header>
        <header
          className="Agenda-item"
          style={{
            padding: "1%",
            textAlign: "center",
          }}
        >
          <h2>Transportation</h2>
          <p>
            <b>4:15 - 4:30PM</b> shuttle will depart from{" "}
            <b>parking lot at St. James the Greater Church parking lot</b> (1366
            Tamm Ave, St. Louis, MO 63139)
          </p>
          <p>
            *** If you choose to leave your car at the parking lot, please park
            in the larger section of the lot located behind the chain-linked
            fence. The gate will not be closed. The parish will be using the
            small parking lot for an evening service.
          </p>
          <p>
            <b>10:30 - 10:45 PM</b> shuttle will depart from reception at Stone
            Soup Cottage.
          </p>
          <p>
            <b>11:00 - 11:15 PM</b> -- guest drop off #1, The Cheshire Hotel
            (6300 Clayton Road St. Louis, MO 63117)
          </p>
          <p>
            <b>11:15 - 11:30 PM</b> -- guest drop off #2, St. James the Greater
            Church parking lot (1336 Tamm Ave, St. Louis, MO 63139)
          </p>
        </header>
        <div style={{ height: "7vh" }}></div>
      </div>
    </Layout>
  );
}

export default Agenda;
